html {
	box-sizing: border-box;
}
*,
*:before,
*:after {
	box-sizing: inherit;
}

:root {
	--color-text: #293649;
}

a {
	text-decoration: none;
}

body {
	color: var(--color-text);
	font-family: Ubuntu;
	-webkit-font-smoothing: antialiased;
}

.relative {
	position: relative;
}

.flex-column {
	display: flex;
	flex-direction: column;
}
