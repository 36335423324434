.rc-tooltip.rc-tooltip-zoom-appear,
.rc-tooltip.rc-tooltip-zoom-enter {
	opacity: 0;
}
.rc-tooltip.rc-tooltip-zoom-enter,
.rc-tooltip.rc-tooltip-zoom-leave {
	display: block;
}
.rc-tooltip-zoom-enter,
.rc-tooltip-zoom-appear {
	opacity: 0;
	animation-duration: 0.3s;
	animation-fill-mode: both;
	animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
	animation-play-state: paused;
}
.rc-tooltip-zoom-leave {
	animation-duration: 0.3s;
	animation-fill-mode: both;
	animation-timing-function: cubic-bezier(0.6, -0.3, 0.74, 0.05);
	animation-play-state: paused;
}
.rc-tooltip-zoom-enter.rc-tooltip-zoom-enter-active,
.rc-tooltip-zoom-appear.rc-tooltip-zoom-appear-active {
	animation-name: rcToolTipZoomIn;
	animation-play-state: running;
}
.rc-tooltip-zoom-leave.rc-tooltip-zoom-leave-active {
	animation-name: rcToolTipZoomOut;
	animation-play-state: running;
}
@keyframes rcToolTipZoomIn {
	0% {
		opacity: 0;
		transform-origin: 50% 50%;
		transform: scale(0, 0);
	}
	100% {
		opacity: 1;
		transform-origin: 50% 50%;
		transform: scale(1, 1);
	}
}
@keyframes rcToolTipZoomOut {
	0% {
		opacity: 1;
		transform-origin: 50% 50%;
		transform: scale(1, 1);
	}
	100% {
		opacity: 0;
		transform-origin: 50% 50%;
		transform: scale(0, 0);
	}
}
.rc-tooltip {
	position: absolute;
	z-index: 1070;
	display: block;
	visibility: visible;
	line-height: 1.5;
	font-size: 12px;
	padding: 1px;
	opacity: 1;
}
.rc-tooltip-hidden {
	display: none;
}
.rc-tooltip-inner {
	padding: 8px 10px;
	color: #fff;
	text-align: left;
	text-decoration: none;
	background-color: #3b3e53;
	border-radius: 10px;
	min-height: 34px;
	max-width: 230px;
	border: 2px solid #3b3e53;
}

.rc-tooltip-placement-top .rc-tooltip-arrow,
.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
	border-top-color: #3b3e53;
	bottom: -5px !important;
}

.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
	border-right-color: #3b3e53;
}

.tooltipContextMenu .rc-tooltip-arrow {
	display: none;
}

.tooltipContextMenu .rc-tooltip-inner {
	padding: 0;
	color: #000;
	text-align: left;
	text-decoration: none;
	background-color: #fff;
	max-width: 230px;
	border-radius: 10px;
	box-shadow: 0 5px 15px 0 rgba(19, 16, 13, 0.1);
	border: solid 1px #e2dfe4;
}

.custom-thumb-tooltip .rc-tooltip-arrow {
	border-top-color: white;
}

.custom-thumb-tooltip {
	padding: 0px;
	border-radius: 5px;
	overflow: hidden;
	background-color: transparent;
	opacity: 1;
}

.custom-thumb-tooltip .rc-tooltip-inner {
	background-color: transparent;
}
